<template>
  <div>
    <v-form ref="inputForm">
      <v-textarea v-model="rawData"  v-if="!rawData"> </v-textarea>
    </v-form>

<div>
  Results

  {{processedData.length}} Responses Looked
  {{noTicketNumber}} Responses without Ticket
  {{noBa}} Responses without BA
</div>


<v-card v-if="rawData">
  <v-card-title>
    Sample Data
  </v-card-title>
  <v-card-text>
    <v-data-table
      v-if="noTicketNumber.length"
      :headers="headers"
      :items="noTicketNumber"
      class="elevation-1"
    ></v-data-table>

    <v-data-table
      :headers="headers"
      :items="filteredResult"
      class="elevation-1"
      height="500px"
    ></v-data-table>
  </v-card-text>
  <v-card-actions>
    <v-btn @click="rawData=''">
      Clear and try again
    </v-btn>
  </v-card-actions>
</v-card>


    <!-- <v-simple-table
    fixed-header
    height="500px"
    v-if="filteredResult.length"
    dense
  >
    <template v-slot:default>
      <thead>
        <tr>
          <th v-for="(item,key) in filteredResult[0]" :key="key">
            {{key}}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="row in filteredResult"
          :key="row.id"
        >
          <td v-for="(item,key) in row" :key="key">{{ item}}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
 -->

  </div>
</template>

<script>
// const parse = require("csv-parse/lib/sync");
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone)

const headerTypes = {
  // pii: [
  //   "Client Name",
  //   "Email",
  //   "Phone Number",
  //   "Ticket Subject",
  //   "Ticket Description",
  //   "Order Number",
  //   "Purchase Amount",
  // ],
  int: [
    "Ticket Number",
    "Brand Ambassador ID",
    "Zendesk Client ID",
    "Likelihood to Recommend (Advisor)",
    "Likelihood to Recommend",
    "Overall Satisfaction with Brand Ambassador",
    "Friendly",
    "Professional",
    "Helpful",
    "Knowledgeable",
    "Understood my needs",
    "Communicated clearly",
    "Helped me in a reasonable time frame",
  ],
  date: ["Response Date", "Ticket Update Date", "Ticket Creation Date"],
  tag: [
    "Customer Care: How tag",
    "Customer Care: Where tag",
    "Customer Care: What tag",
    "Customer Care: Why tag",
    "Customer Care: Top Products tag",
    "Customer Care: Sentiment tag",
  ],
  other: [
    "Brand Ambassador",
    "Brand Ambassador Email Address",
    "Issue Resolved",
    "Number of Attempts",
    "Ticket Channel Group",
    "Number of Contacts (Issue)",
    "Visited Chanel Boutique In Person",
    // "Not Contacted Chanel and Left Issue/Request Unresolved",
    // "Contacted Using Live Chat",
    // "Contact Chanel Boutique by Phone",
    // "Contact Chanel On Social Media",
    // "Contact Customer Care by Phone",
    "Reason for Score",
    "Issue Resolution",
    "Anything Else",
    "Not Contacted Chanel and Left Issue/Request Unresolved"
  ],
};

const allowedHeaders = Object.keys(headerTypes).reduce(
  (arr, key) => [...arr, ...headerTypes[key]],
  []
);

console.log({ allowedHeaders });

import { parse } from "csv-parse/lib/sync";

const getInitials = (name) => {
  let names = (name || "").split(" ");
  return names.map((word) => word.charAt(0).toUpperCase()).join("");
};

export default {
  data() {
    return {
      zendeskInstance: "chanelusa.zendesk.com",
      rawData: "",
    };
  },
  computed: {
    processedData() {
      let records = parse(this.rawData.toString(), {
        columns: true,
        skip_empty_lines: true,
        delimiter: ["\t"],
        trim: true,
        relax_quotes: true,
        bom: true,
      });
      console.log({ records });
      return records
        .map((record) => {
          let newRecord = {
            id: null,
            zendesk_instance: this.zendeskInstance,
            ticket_number: null,
            response_timestamp: null,
            response_date: null,
            ticket_creation_date: null,
            ticket_update_date: null,
            brand_ambassador: null,
            brand_ambassador_email_address: null,
            brand_ambassador_id: null,
            ticket_channel_group: null,
            zendesk_client_id: null,
            likelihood_to_recommend: null,
            likelihood_to_recommend_advisor: null,
            overall_satisfaction_with_brand_ambassador: null,
            friendly: null,
            professional: null,
            helpful: null,
            knowledgeable: null,
            understood_my_needs: null,
            communicated_clearly: null,
            helped_me_in_a_reasonable_time_frame: null,
            issue_resolution: null,
            number_of_contacts_issue: null,
            visited_chanel_boutique_in_person: null,
            not_contacted_chanel_and_left_issue_request_unresolved: null,
            reason_for_score: null,
            anything_else: null,
            tags: [],
            email_domain: record.Email
              ? record.Email.split("@")[1] || null
              : null,
          };
          let initals = getInitials(record["Client Name"]);
          Object.keys(record).forEach((key) => {
            if (key.match("(Inactive)")) {
              return;
            }
            if (allowedHeaders.indexOf(key) < 0) {
              // ignore
              return;
            }

            let newKey = key
              .toLowerCase()
              .trim()
              .replace(/[\s\/\(\)]/g, "_")
              .replace(/_+/g, "_")
              .replace(/_$/g, "");

            if (record[key] === "" || record[key] === null) {
              record[key] = null;
            } else if (key == "Brand Ambassador") {
              newRecord[newKey] = record["Brand Ambassador Email Address"]
                ? record["Brand Ambassador Email Address"]
                    .split("@")[0]
                    .toLowerCase()
                : record[key];
            } else if (key == "Brand Ambassador Email Address") {
              newRecord[newKey] =
                record["Brand Ambassador Email Address"].toLowerCase();
            } else if (headerTypes.int.indexOf(key) > -1) {
              newRecord[newKey] = +record[key];
            } else if (headerTypes.date.indexOf(key) > -1) {
              newRecord[newKey] = dayjs(record[key],'PST8EDT').tz("EST5EDT").format('YYYY-MM-DD');
              if (newKey === 'response_date'){
                newRecord.response_timestamp = dayjs(record[key],'PST8EDT').tz("EST5EDT").toDate()
              }
            } else if (headerTypes.tag.indexOf(key) > -1) {
              newRecord.tags.push(record[key]);
            } else if (headerTypes.other.indexOf(key) > -1) {
              newRecord[newKey] = record[key];
            } else {
              // newRecord[newKey] = record[key];
            }
            newRecord.id = `${dayjs(newRecord.response_timestamp)
              .utc()
              .format("YYYY-MM-DD_HH:mm:ssZ")}_${initals}_${
              newRecord.ticket_number
            }`;
          });
          if (!initals && !newRecord.ticket_number) {
            return null;
          }
          return newRecord;
        })
        .filter((record) => record);
    },
    filteredResult() {
      return this.processedData.slice(0, 10);
    },
    noTicketNumber() {
      return this.processedData.filter((record) => !record["ticket_number"]).length;
    },
    noBa() {
      return this.processedData.filter((record) => !record["brand_ambassador"]).length;
    },

    headers() {
      if (!this.processedData.length) {
        return [];
      }
      return Object.keys(this.processedData[0]).map((key) => ({
        text: key,
        value: key,
      }));
    },
  },
};
</script>

<style>
</style>