<template>
  <v-card>
    data import
    {{ section }}
    <ChanelMedallia />
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import ChanelMedallia from "./ChanelMedallia.vue";

export default {
  data() {
    return {
      imports: [
        {
          name: "Chanel Medallia",
          path: "/data-import/chanel-medallia",
          roles: ["ops"],
          clients: ["chanel"],
        },
      ],
    };
  },
  computed: {
    ...mapState(["pages", "auth"]),
    page() {
      return this.pages.filter((page) => page.name == "Data Import")[0] || {};
    },
    section() {
      if (!this.$route.params.section) {
        return null;
      }
      let section = this.page.sections[this.$route.params.section];
      section.id = this.$route.params.section;
      return section;
    },
  },
  components: {
    ChanelMedallia,
  },
};
</script>

<style>
</style>